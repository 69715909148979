<template>
  <v-flex xs12>
    <v-card v-if="onCreate" tile flat color="grey lighten-4" class="mb-5">
      <v-card-title class="grey lighten-3">
        {{ $t("buttons.add_skill") }}
      </v-card-title>
      <v-card-text>
        <validation-observer ref="form">
          <v-layout row wrap align-center>
            <v-flex xs12>
              <v-radio-group row v-model="skill.skillType" mandatory>
                <v-radio
                  color="primary"
                  :value="SKILL_TYPES.TECHNICAL"
                  :label="$t(`hr.hiring.skill_type.${SKILL_TYPES.TECHNICAL.toLowerCase()}`)"
                />
                <v-radio
                  color="primary"
                  :value="SKILL_TYPES.SOFT"
                  :label="$t(`hr.hiring.skill_type.${SKILL_TYPES.SOFT.toLowerCase()}`)"
                />
                <v-radio
                  color="primary"
                  :value="SKILL_TYPES.QUESTION"
                  :label="$t(`hr.hiring.skill_type.${SKILL_TYPES.QUESTION.toLowerCase()}`)"
                />
                <v-radio
                  color="primary"
                  :value="SKILL_TYPES.LINK"
                  :label="$t(`hr.hiring.skill_type.${SKILL_TYPES.LINK.toLowerCase()}`)"
                />
              </v-radio-group>
            </v-flex>
            <v-flex xs12>
              <validation-provider name="skillName" rules="required" v-slot="{ errors }">
                <v-text-field
                  :label="skill.skillType === SKILL_TYPES.LINK ? $t('hr.hiring.link') : $t('hr.hiring.skill')"
                  :loading="loading"
                  v-model="skill.skillName"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-flex>

            <v-layout row wrap justify-center align-center>
              <v-flex xs6>
                <v-subheader class="pl-3 mb-0 font-weight-bold" style="height: auto">{{
                  $t("hr.hiring.rating")
                }}</v-subheader>
                <v-rating v-model="rating" x-large hover :color="getColorRating()" background-color="grey lighten-1" />
              </v-flex>
              <v-flex xs6 class="text-right">
                <v-chip label text-color="white" :color="getColor()">
                  {{ getSkillText() }}
                </v-chip>
              </v-flex>
            </v-layout>
          </v-layout>
        </validation-observer>
      </v-card-text>
      <v-card-actions class="pb-5 pt-5 px-5 mt-3">
        <v-layout row wrap justify-space-between>
          <v-btn depressed color="grey lighten-3" class="mr-2" :disabled="loading" @click.stop="onClickCancel">
            {{ $t("buttons.cancel") }}
          </v-btn>
          <v-btn depressed color="primary" :disabled="loading" @click.stop="validateAndAdd">
            {{ $t("buttons.save") }}
          </v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
    <v-layout justify-end v-else mt-2 mb-5>
      <v-btn :disabled="loading" depressed color="teal" dark @click.stop="addNewBreak">
        <v-icon>mdi-plus</v-icon>
        {{ $t("buttons.add_skill") }}
      </v-btn>
    </v-layout>
  </v-flex>
</template>

<script>
  /**
   * @project idenfit
   * @developer Halil Kılıçarslan
   * @description AssessmentSkillDTO Component Logic
   * @date 14.05.2020
   */
  import { SKILL_TYPES } from "@/helpers/enums";
  import AssessmentSkillDTO from "../model/AssessmentSkillDTO";
  import SkillDTO from "./model/SkillDTO";

  export default {
    name: "AddSkillAssessment",
    props: {
      addedSkills: {
        type: Array,
        required: true
      }
    },

    data: () => ({
      SKILL_TYPES,
      loading: false,
      onCreate: false,
      skill: new SkillDTO(),
      skillAssessmentDTO: null,
      beginDateMenu: false,
      endDateMenu: false,
      rating: 1
    }),
    watch: {
      onCreate: {
        handler(v) {
          this.$emit("on:create", v);
        },
        deep: true
      }
    },
    methods: {
      validateAndAdd() {
        this.$refs.form.validate().then(valid => {
          if (valid) {
            let isExists = this.addedSkills.map(item => item.skillName).indexOf(this.skill.skillName);
            if (isExists === -1) {
              this.loading = true;

              this.$api_hiring.skillService
                .save(this.skill)
                .then(({ data }) => {
                  if (!data.error) {
                    this.skillAssessmentDTO = new AssessmentSkillDTO();
                    this.skillAssessmentDTO.skillId = data.data;
                    this.skillAssessmentDTO.skill = this.skill;
                    this.skillAssessmentDTO.skillAssessment = this.getSkillValue();
                    this.$emit("added", this.skillAssessmentDTO);
                    this.onCreate = false;
                    this.skillAssessmentDTO = null;
                    this.skill = null;
                    this.rating = 1;
                  }
                })
                .catch(e => {
                  this.$helpers.showNotification(e.toString(), "error", true);
                })
                .finally(() => {
                  this.loading = false;
                });
            } else {
              this.$helpers.showNotification("Böyle bir özellik mevcut", "error", true);
            }
          }
        });
      },
      resetStates() {
        this.onCreate = false;
        this.skill = {};
        this.rating = 1;
        this.$refs.form.reset();
      },
      onClickCancel() {
        this.resetStates();
      },
      addNewBreak() {
        this.onCreate = true;
        this.skill = new SkillDTO();
      },
      getColorRating() {
        let color = null;
        switch (this.rating) {
          case 1:
            color = "red";
            break;
          case 2:
            color = "red lighten-1";
            break;
          case 3:
            color = "orange lighten-1";
            break;
          case 4:
            color = "green lighten-1";
            break;
          case 5:
            color = "green darken-2";
            break;
          default:
            color = "red";
        }
        return color;
      },
      getColor() {
        let color = null;
        switch (this.rating) {
          case 1:
            color = "red";
            break;
          case 2:
            color = "red lighten-2";
            break;
          case 3:
            color = "orange lighten-1";
            break;
          case 4:
            color = "green lighten-1";
            break;
          case 5:
            color = "green darken-2";
            break;
          default:
            color = "red";
        }
        return color;
      },
      getSkillText() {
        let text = null;
        switch (this.rating) {
          case 1:
            text = this.$t(`assessment.very_poor`);
            break;
          case 2:
            text = this.$t(`assessment.poor`);
            break;
          case 3:
            text = this.$t(`assessment.normal`);
            break;
          case 4:
            text = this.$t(`assessment.good`);
            break;
          case 5:
            text = this.$t(`assessment.very_good`);
            break;
          default:
            text = this.$t(`assessment.very_poor`);
        }
        return text;
      },
      getSkillValue() {
        let text = null;
        switch (this.rating) {
          case 1:
            text = "VERY_POOR";
            break;
          case 2:
            text = "POOR";
            break;
          case 3:
            text = "NORMAL";
            break;
          case 4:
            text = "GOOD";
            break;
          case 5:
            text = "VERY_GOOD";
            break;
          default:
            text = "VERY_GOOD";
        }
        return text;
      }
    }
  };
</script>

<style scoped></style>
